

document.addEventListener('DOMContentLoaded', function(event) {
    // console.log(window.location);

    var contentIndex = document.getElementById('contentIndex'),
        contentLogin = document.getElementById('contentLogin'),
        contentLifeforms = document.getElementById('contentLifeforms'),
        navKirtland = document.getElementById('navKirtland'),
        navSpellbound = document.getElementById('navSpellbound'),
        navRadio = document.getElementById('navRadio'),
        inputPass = document.getElementById('inputPass'),
        inputSubmit = document.getElementById('inputSubmit'),
        logoIndex2 = document.getElementById('logoIndex2'),
        passGlobal = '',
        currentPage = 0;

    function login(pass) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            console.log(this.responseText);
            var errorElem = document.getElementById('loginError')
                inputPass = document.getElementById('inputPass');

            errorElem.classList.add('d-none');
            errorElem.classList.remove('d-block');
            inputPass.classList.remove('error');

            if( this.responseText ) {
                var response = JSON.parse(this.responseText);
                console.log('response');
                console.log(response);

                if( response.success ) {
                    console.log('response.success');
                    console.log(response.success);

                    passGlobal = pass;

                    var lifeLabel = document.getElementById('lifeLabel'),
                        lifeText = document.getElementById('lifeText'),
                        lifeVideo = document.getElementById('lifeVideo'),
                        lifeLink = document.getElementById('lifeLink');
                    lifeLabel.innerHTML = '';
                    lifeText.innerHTML = '';
                    lifeVideo.innerHTML = '';

                    if( response.success.label ) {
                        lifeLabel.innerHTML = response.success.label;
                    }

                    if( response.success.text ) {
                        lifeText.innerHTML = response.success.text;
                    }

                    if( response.success.video ) {
                        var source = document.createElement('source');
                        source.setAttribute('src', response.success.video);
                        lifeVideo.appendChild(source);
                        lifeVideo.load();

                        setTimeout(function(){
                            lifeVideo.play();
                        }, 300);
                    }

                    if( response.success.link ) {
                        if( response.success.link.url && response.success.link.text ) {
                            lifeLink.innerHTML = response.success.link.text;
                            lifeLink.setAttribute('href', response.success.link.url);
                        }
                    } else {
                        lifeLink.innerHTML = '';
                        lifeLink.setAttribute('href', '#');
                    }

                    openLifeforms();
                }

                if( response.error ) {
                    console.log('response.error');
                    console.log(response.error);
                    openLogin();

                    if( passGlobal !== '' ) {
                        errorElem.classList.remove('d-none');
                        errorElem.classList.add('d-block');

                        inputPass.classList.add('error');
                    }
                }
            }
        };
        xhttp.open("GET", "inc/login.php?pass="+pass+"&page="+currentPage, true);
        xhttp.send();
    }

    function openIndex(){

        contentIndex.classList.add('d-flex');
        contentIndex.classList.remove('d-none');

        contentLogin.classList.remove('d-flex');
        contentLogin.classList.add('d-none');

        contentLifeforms.classList.remove('d-flex');
        contentLifeforms.classList.add('d-none');

        inputPass.focus();
    }

    function openLogin(){

        contentIndex.classList.remove('d-flex');
        contentIndex.classList.add('d-none');

        contentLogin.classList.remove('d-none');
        contentLogin.classList.add('d-flex');

        contentLifeforms.classList.remove('d-flex');
        contentLifeforms.classList.add('d-none');

        inputPass.focus();
    }

    function openLifeforms(){

        contentIndex.classList.remove('d-flex');
        contentIndex.classList.add('d-none');

        contentLogin.classList.remove('d-flex');
        contentLogin.classList.add('d-none');

        contentLifeforms.classList.add('d-flex');
        contentLifeforms.classList.remove('d-none');
    }

    navKirtland.addEventListener('click', function(event) {
        event.preventDefault();
        currentPage = 1;
        login(passGlobal);
    });

    navSpellbound.addEventListener('click', function(event) {
        event.preventDefault();
        currentPage = 2;
        login(passGlobal);
    });

    navRadio.addEventListener('click', function(event) {
        event.preventDefault();
        currentPage = 3;
        login(passGlobal);
    });

    inputSubmit.addEventListener('click', function(event) {
        event.preventDefault();

        if( inputPass ) {
            passGlobal = inputPass.value;
        }

        login( passGlobal );
    });

    inputPass.addEventListener("keyup", event => {

        if( inputPass.classList.contains('error')) {
            inputPass.classList.remove('error');
        }

        if (event.keyCode === 13) {
            inputSubmit.click();
        }
    });

    logoIndex2.addEventListener('click', function(event) { openIndex(); });
    logoIndex3.addEventListener('click', function(event) { openIndex(); });
});
